import { defineComponent, reactive, toRefs, computed } from 'vue';
import Card from '@/components/Card';
import { ElMessageBox } from "element-plus";
import { useStore } from "vuex";
import API from "@/api/authority/index";
import { useMessage } from "@/hooks/web/useMessage";
import { passwordStrength } from "check-password-strength";
export default defineComponent({
  name: 'LogoSettings',
  components: {
    Card
  },
  setup() {
    const {
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      oldPassword: '',
      newPassword: '',
      type: 0,
      confirmPassword: ''
    });
    const name = computed(() => state.userInfo.username);
    //密码强度检测
    const handleRegPassword = () => {
      const status = passwordStrength(refData.newPassword).value;
      if (status == 'Medium' && refData.newPassword.length > 6) {
        refData.type = 3;
      } else if (status == 'Weak' || refData.newPassword.length > 6) {
        refData.type = 2;
      } else {
        refData.type = 1;
      }
    };
    //保存
    const handleSave = async () => {
      try {
        if (!refData.oldPassword) throw {
          msg: '请填写旧密码'
        };
        if (!refData.newPassword) throw {
          msg: '请填写新密码'
        };
        if (!refData.confirmPassword) throw {
          msg: '请输入确认密码'
        };
        if (refData.confirmPassword != refData.newPassword) throw {
          msg: '两次密码不相同，请重新输入'
        };
        if (refData.newPassword == refData.oldPassword) throw {
          msg: '新密码不能与旧密码相同'
        };
        ElMessageBox.confirm(`是否确认“修改密码”`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          const {
            code,
            msg
          } = await API.updatePassword({
            oldPassword: refData.oldPassword,
            newPassword: refData.newPassword
          });
          // oldPassword:handleEncrypt(refData.oldPassword),
          //  newPassword:handleEncrypt(refData.newPassword),
          message[code == 0 ? 'success' : 'warning'](code == 0 ? '修改成功' : msg);
          if (code == 0) {
            refData.oldPassword = '';
            refData.newPassword = '';
            refData.type = 0;
            refData.confirmPassword = '';
          }
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    return {
      ...toRefs(refData),
      name,
      handleSave,
      handleRegPassword
    };
  }
});